<template>
  <div
    :class="[
      $style.productBundle,
      {
        [$style.productBundleCombi]: bundleType === PO_COMBI,
      },
    ]"
  >
    <div :class="$style.bundleHeader">
      <span aria-hidden="true" :class="$style.bundleIcon">
        <lv-icon name="pig-16" :size="16" />
      </span>
      <h2
        :class="$style.bundleTitle"
        v-text="({
          [PO_COMBI]: $awt('aw.common.product.package_offer.combi.title'),
          [PO_BUNDLE]: $awt('aw.common.product.package_offer.bundle.title'),
        })[bundleType] || '-'"
      />
      <span :class="$style.bundleDate">
        <time :datetime="bundle.activeFrom" v-text="dateStrings.activeFrom" />
        <span v-text="'-'" />
        <time :datetime="bundle.activeTo" v-text="dateStrings.activeTo" />
      </span>
    </div>
    <component :is="isListOfProducts ? 'ul' : 'div'" :class="['ulReset', $style.bundleProducts]">
      <component
        :is="isListOfProducts ? 'li' : 'div'"
        v-for="product in bundleProducts.items"
        :key="product.id"
        :class="$style.bundleProduct"
      >
        <div :class="$style.bundleItem">
          <div :class="$style.itemData">
            <nuxt-link :to="getProductUrl(product.selectedVariant)">
              <h3
                :class="[
                  $style.itemTitle,
                  {
                    [$style.itemTitleHasTwoPrices]: !bundlePrices[product.id].isTheSame,
                    [$style.itemTitleHasQuantity]: bundleProducts.counts[product.id] !== 1,
                  },
                ]"
                v-text="product.selectedVariant.name"
              />
            </nuxt-link>
            <span
              v-if="bundleProducts.counts[product.id] !== 1"
              :class="$style.itemLeadQuantity"
              v-text="$awt(
                'aw.common.product.package_offer.bundle.lead_quantity_text',
                {
                  quantity: bundleProducts.counts[product.id],
                },
              )"
            />
            <div>
              <del v-if="!bundlePrices[product.id].isTheSame" :class="$style.itemPriceGross" v-text="bundlePrices[product.id].gross" />
              <span :class="$style.itemPriceGrossDiscounted" v-text="bundlePrices[product.id].grossDiscounted" />
            </div>
          </div>
          <div :class="$style.itemImg">
            <lv-image v-bind="bundleImages[product.id]" />
          </div>
        </div>
        <span aria-hidden="true" :class="$style.bundleProductPlus" v-text="'+'" />
      </component>
    </component>
    <div :class="$style.bundleControls">
      <div>
        <del :class="$style.priceGross" v-text="priceGross" />
        <span :class="$style.priceGrossDiscounted" v-text="priceGrossDiscounted" />
      </div>
      <lv-button
        styling="small-primary-dark"
        :class="$style.bundleToCartBtn"
        :loading-id="loadingId"
        :aria-disabled="Boolean(statusMessage)"
        :disabled-style="Boolean(statusMessage)"
        @click="addBundleToBasket"
      >
        <span v-if="statusMessage" v-html="statusMessage.shortText ? $awt(statusMessage.shortText) : ''" />
        <span v-else v-text="$awt('aw.common.product.package_offer.into_cart')" />
      </lv-button>
    </div>
  </div>
</template>

<script setup>
  import { useNuxtApp } from 'nuxt/app';
  import { removeTZInfoFromDateStr } from '~~/common/utils';
  import { getThumbImageByVariant, getShowedStatusMessageFull } from '~~/common/mixins/productDataMixin.js';
  import { LvIcon, LvImage, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { getProductUrl } from '~~/common/mixins/urlMixin.js';
  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useVideoStore } from '~~/common/stores/video';

  const props = defineProps({
    bundle: {
      type: Object,
      required: true,
    },
  });

  const PO_BUNDLE = Symbol('bundle');
  const PO_COMBI = Symbol('combi');

  const {
    $date,
    $price,
    $awMergedConfig: {
      isShop,
    },
  } = useNuxtApp();

  const leadProduct = computed(() => props.bundle?.leadProduct);
  const relatedProducts = computed(() => {
    return (Array.isArray(props.bundle?.relatedProducts)
      ? props.bundle.relatedProducts
      : []
    );
  });
  const bundleType = computed(() => {
    const leadProductId = leadProduct.value?.id;
    const firstRelatedProductId = relatedProducts.value[0]?.id;
    if (!leadProductId) {
      return null;
    } else if (leadProductId === firstRelatedProductId) {
      return PO_BUNDLE;
    } else {
      return PO_COMBI;
    }
  });
  const dateStrings = computed(() => {
    let { activeTo, activeFrom } = props.bundle || {};
    if (!activeTo || !activeFrom) {
      return '';
    }
    [activeFrom, activeTo] = [activeFrom, activeTo].map((dateString) => {
      return new Date(removeTZInfoFromDateStr(dateString));
    });
    const isSameYear = activeTo.getFullYear() === activeFrom.getFullYear();
    const isSameMonth = activeTo.getMonth() === activeFrom.getMonth();
    return {
      activeFrom: $date(activeFrom, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }, {
        removeSpaces: true,
        removeLastDot: true,
      }),
      activeTo: $date(activeTo, {
        year: !isSameYear ? 'numeric' : undefined,
        month: !isSameYear || !isSameMonth ? '2-digit' : undefined,
        day: '2-digit',
      }, {
        removeSpaces: true,
      }),
    };
  });
  const bundleProducts = computed(() => {
    const prods = [
      ...yieldTruthy(
        ...new Array(props.bundle?.leadQuantity).fill(leadProduct.value),
        ...relatedProducts.value,
      ),
    ];
    return prods.reduce(
      (acc, curr) => {
        const { counts, items } = acc;
        const id = curr?.id;
        (counts[id]
          ? counts[id]++
          : counts[id] = 1
        );
        if (counts[id] === 1) {
          items.push(curr);
        }
        return acc;
      },
      {
        counts: {},
        items: [],
      },
    );
  });
  const statusMessage = computed(() => {
    return bundleProducts.value.items.reduce(
      (acc, curr) => {
        return acc || getShowedStatusMessageFull({ availability: curr.selectedVariant.cartInfo.availability });
      },
      undefined,
    );
  });
  const bundlePrices = computed(() => {
    return bundleProducts.value.items.reduce(
      (acc, curr) => {
        const prices = curr?.selectedVariant?.[bundleType.value === PO_BUNDLE ? 'bundlePrice' : 'price'];
        acc[curr.id] = {
          isTheSame: prices?.gross === prices?.grossDiscounted,
          gross: getPrice(prices, 'gross'),
          grossDiscounted: getPrice(prices, 'grossDiscounted'),
        };
        return acc;
      },
      {},
    );
  });
  const bundleImages = computed(() => {
    const { videoCache, fetchVideoToCache } = useVideoStore();

    return bundleProducts.value.items.reduce(
      (acc, curr) => {
        const id = curr.id;
        const timg = getThumbImageByVariant(curr.selectedVariant);
        if (timg.videoId) {
          fetchVideoToCache(timg.videoId);
          acc[id] = {
            key: videoCache[timg.videoId]?.thumbnail_url,
            type: 'youtube',
            sources: [{ url: videoCache[timg.videoId]?.thumbnail_url || '' }],
          };
        } else {
          acc[id] = {
            type: 'variant',
            sources: timg,
            sizing: 'contain',
            alt: curr.selectedVariant.name,
          };
        }
        return acc;
      },
      {},
    );
  });
  const isListOfProducts = computed(() => bundleProducts.value.items.length > 1);
  const loadingId = computed(() => `bundle-${props.bundle.packageId}`);

  const priceGross = computed(() => {
    return getPrice(props.bundle?.price, 'gross');
  });
  const priceGrossDiscounted = computed(() => {
    return getPrice(props.bundle?.price, 'grossDiscounted');
  });

  function getPrice (price, key) {
    return price?.[key] && price.currency ? $price({ gross: price[key] }, price.currency) : '-';
  }
  async function addBundleToBasket () {
    if (statusMessage.value) {
      return;
    }
    const deliveryStore = useDeliveryStore();
    const cartData = {
      id: 'current',
      objectType: 'productPackage',
      data: {
        objectId: `${props.bundle?.packageId}`,
        stockType: 'store',
        quantity: {
          quantity: 1,
          type: 'piece',
        },
      },
    };
    if (isShop && !deliveryStore.setup) {
      const { openDeliveryMethodModal } = useModalStore();
      openDeliveryMethodModal({ cartData });
    } else {
      const { addToCart } = useOrderStore();
      const { startLoading, endLoading } = useUserInterfaceStore();
      const id = loadingId.value;
      try {
        startLoading({ id });
        await addToCart(cartData);
      } finally {
        endLoading({ id });
      }
    }
  }
  function* yieldTruthy (...elements) {
    for (const e of elements) {
      if (e) {
        yield e;
      }
    }
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.productBundle {
  --productBundlePad: 20px;
  padding: var(--productBundlePad) 16px 0 16px;
  border-radius: 16px;
  background: $color-white;

  &Combi {
    @include tablet(max) {
      --productBundlePad: 16px;
    }
  }
}

.bundleHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bundleTitle {
  @include font(900, 14px, 20px);

  @include tablet(min) {
    @include font(null, 16px, 24px);
  }
}

.bundleIcon {
  line-height: 0;
  padding: 6px;
  color: $color-white;
  border-radius: 8px;
  background: $color-strawberry-500;
}

.bundleDate {
  @include font(500, 10px, 16px);
  margin-left: auto;

  @include tablet(min) {
    @include font(null, 12px, null);
  }
}

.bundleControls {
  display: flex;
  gap: 32px;
  padding: 16px 0;
  border-top: 1px solid $color-border-disabled;
}

.bundleToCartBtn {
  flex: 1;
  max-width: 100%;
  height: 32px;
}

.priceGross {
  @include font(900, 12px, 16px);
  display: block;
}

.priceGrossDiscounted {
  @include font(900, 16px, 20px);

  @include tablet(min) {
    @include font(null, 18px, 24px);
  }
}

.bundleProducts {
  display: flex;
  padding: var(--productBundlePad) 0;

  @include tablet(max) {
    flex-direction: column;
  }
}

.bundleProduct {
  display: flex;

  @include tablet(max) {
    flex-direction: column;
  }

  @include desktop-small(min) {
    flex: 1 0 44.8px;
    width: 0;

    &:last-child {
      flex-basis: 0;
    }
  }

  &:last-child {
    .bundleProductPlus {
      display: none;
    }
  }
}

.bundleProductPlus {
  @include font(900, 22px, 0);
  align-self: center;
  justify-self: center;
  padding: 16px;
}

.bundleItem {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  gap: 0 8px;
}

.itemData {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  @include desktop-small(min) {
    justify-content: space-between;
  }
}

.itemTitle {
  @include font(500, 12px, 16px);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &:not(&HasQuantity) {
    @include tablet(max) {
      margin-bottom: 8px;
    }
  }

  &HasTwoPrices {
    @include tablet(max) {
      -webkit-line-clamp: 1;
    }
  }

  @include tablet(min) {
    @include font(null, 14px, 20px);
  }
}

.itemImg {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  padding: 8px;
  border-radius: 8px;
  background: $color-background-2;
}

.itemLeadQuantity {
  @include font(900, 10px, 16px);
  align-self: start;
  order: -1;
  padding: 2px 8px;
  color: $color-strawberry-500;
  border-radius: 6px;
  background: change-color($color-strawberry-450, $alpha: 0.12);

  @include tablet(min) {
    @include font(null, 12px, null);
  }
}

.itemPriceGross {
  @include font(900, 10px, 16px);
  display: block;

  @include tablet(min) {
    @include font(null, 12px, null);
  }
}

.itemPriceGrossDiscounted {
  @include font(900, 14px, 20px);

  @include tablet(min) {
    @include font(null, 16px, 24px);
  }
}
</style>
