<template>
  <div :class="$style.loyaltyProgramDescription">
    <div v-if="loyaltyCardLevelInformation && selectedLevel" :class="$style.chartContainer">
      <aw-loyalty-chart :card-levels="loyaltyCardLevelInformation" :selected-level="selectedLevel.cardLevel ?? 0" />
    </div>
    <div v-if="loyaltyCardLevelInformation">
      <div :class="$style.loyaltyProgramDescriptionGrid">
        <template v-for="level in loyaltyCardLevelInformation" :key="level.displayName">
          <div :class="[$style.loyaltyProgramDescriptionItemContainer, $style.loyaltyProgramDescriptionGridItem]">
            <div :class="[$style.loyaltyProgramDescriptionItem, $style[`loyaltyProgramDescriptionItemLevel${level.cardLevel}`], {[$style[`loyaltyProgramDescriptionItemLevel${level.cardLevel}Selected`]]: selectedLevel?.cardLevel === level.cardLevel }]" @click="selectLevel(level)">
              <client-only>
                <lv-icon
                  :name="`loyalty-bird-${level.cardLevel}`"
                  :size="32"
                  :class="[$style.loyaltyProgramDescriptionItemBird,
                           {[$style.loyaltyProgramDescriptionItemBirdSelected]: selectedLevel?.cardLevel === level.cardLevel},
                           {[$style.loyaltyProgramDescriptionItemBirdLeaveSelected]: previousSelectedLevel?.cardLevel === level.cardLevel}
                  ]"
                />
              </client-only>
              <div :class="$style.loyaltyProgramDescriptionItemName">
                {{
                  $awt(`aw.profile.loyalty_card.details.level_${level.cardLevel}_short`)
                }}
              </div>
              <div v-if="level.monthlySpendingFrom && level.monthlySpendingTo" :class="$style.loyaltyProgramDescriptionItemSpending">
                {{ $awt('aw.loyalty_program.description.monthly_from_to', { from: Math.round(level.monthlySpendingFrom / 1000), to: Math.round(level.monthlySpendingTo / 1000) }) }}
              </div>
              <div v-else-if="level.monthlySpendingFrom" :class="$style.loyaltyProgramDescriptionItemSpending">
                {{ $awt('aw.loyalty_program.description.monthly_from', { from: Math.round(level.monthlySpendingFrom / 1000) }) }}
              </div>
              <div v-else-if="level.monthlySpendingTo" :class="$style.loyaltyProgramDescriptionItemSpending">
                {{ $awt('aw.loyalty_program.description.monthly_from_to', { from: 0, to: Math.round(level.monthlySpendingTo / 1000) }) }}
              </div>
            </div>
          </div>
          <lv-icon
            v-if="level.cardLevel !== loyaltyCardLevelInformation.length - 1"
            name="loyalty-level-arrow"
            :size="19"
            :class="[$style.loyaltyProgramDescriptionItemIcon, $style[`loyaltyProgramDescriptionItemIconLevel${level.cardLevel}`]]"
          />
        </template>
      </div>
      <div
        :class="[$style.loyaltyProgramDescriptionFurtherInformation,
                 {[$style.loyaltyProgramDescriptionFurtherInformationMultipleRows]: selectedLevel.loyaltyPointsReward && selectedLevel.loyaltyPointsPer1000Huf },
                 {[$style.loyaltyProgramDescriptionFurtherInformationSingleRow]: !(selectedLevel.loyaltyPointsReward && selectedLevel.loyaltyPointsPer1000Huf) }
        ]"
      >
        <div v-if="selectedLevel.loyaltyPointsReward" :class="$style.loyaltyProgramDescriptionFurtherInformationRow">
          <div :class="$style.loyaltyProgramDescriptionFurtherInformationRowText">
            <lv-icon name="check-in-circle-16" :size="24" :class="$style.loyaltyProgramDescriptionFurtherInformationRowTextIcon" />
            {{ $awt('aw.loyalty_program.description.monthly_reward', { points: selectedLevel.loyaltyPointsReward }) }}
          </div>
        </div>
        <div v-if="selectedLevel.loyaltyPointsPer1000Huf" :class="$style.loyaltyProgramDescriptionFurtherInformationRow">
          <div :class="$style.loyaltyProgramDescriptionFurtherInformationRowText">
            <lv-icon name="check-in-circle-16" :size="24" :class="[$style.loyaltyProgramDescriptionFurtherInformationRowTextIcon]" />
            {{ $awt('aw.loyalty_program.description.points_per_amount', { points: selectedLevel.loyaltyPointsPer1000Huf }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineAsyncComponent } from 'vue';
  import { useNuxtApp } from 'nuxt/app';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  const AwLoyaltyChart = defineAsyncComponent(() => import('~~/shop/components/Page/Profile/Loyalty/Chart/AwLoyaltyChart.vue'));

  const {
    $api,
    $logger,
  } = useNuxtApp();

  const selectedLevel = ref({});
  const previousSelectedLevel = ref({});
  const loyaltyCardLevelInformation = ref(null);

  const selectLevel = (level) => {
    previousSelectedLevel.value = Object.assign({}, selectedLevel.value); selectedLevel.value = level;
  };

  onMounted(async () => {
    try {
      const result = await $api.$get('/loyalty_card_level_info');
      loyaltyCardLevelInformation.value = result;
      if (result.length) {
        selectedLevel.value = result[0];
      }
    } catch (error) {
      $logger.error(error);
    }
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
  .loyaltyProgramDescription {
    overflow: clip;
    min-height: 555px;

    padding-top: 20px;
    @include tablet(min) {
      margin-right: 80px;
      margin-left: 80px;
    }

    &Grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      align-items: center;

      @include tablet(min) {
        grid-template-columns: 1fr 20px 1fr 20px 1fr;
        gap: 40px;

      }
    }

    &FurtherInformation {
      margin-top: 24px;
      padding: 16px;
      border-radius: 12px;
      background: $color-background-2;

      &MultipleRows {
        height: 195px;
        transition: height 200ms ease-in-out;
        animation: animate_further_row_multiple 200ms ease-in-out;
      }

      &SingleRow {
        height: 115px;
        transition: height 200ms ease-in-out;
        animation: animate_further_row_single 200ms ease-in-out;
      }

      &Row {
        padding: 16px 16px 0;
        border-radius: 12px 12px 0 0;
        background: $color-white;

        &Text {
          font-size: 14px;
          font-weight: $font-weight-bold-v2;
          line-height: 20px;
          padding: 12px 12px 28px;
          text-align: center;
          border-bottom: 1px dashed $color-cloud;

          &Icon {
            margin-right: 5px;

            svg {
              color: $color-sage-450;
            }
          }
        }

        &:first-of-type {
          border-radius: 12px 12px 0 0;
        }

        &:last-of-type {
          border-radius: 0 0 12px 12px;

          .loyaltyProgramDescriptionFurtherInformationRowText {
            border-bottom: none;
          }
        }
      }
    }

    &Item {
      @include tablet(min) {
        padding: 7px 6px 11px;
      }

      position: relative;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
      padding: 7px 7px 11px;
      cursor: pointer;
      transition: background 400ms ease;
      text-align: center;
      border: 1px solid transparent;
      border-radius: 12px;

      &Container {
        position: relative;
      }

      &Icon {
        @include tablet(min) {
          display: block;
        }
        font-size: 23px;

        display: none;
      }

      &Name {
        font-size: 14px;
        font-weight: $font-weight-bold-v2;
        line-height: 20px;
      }

      &Spending {
        font-size: 12px;
        line-height: 16px;
      }

      &Level0 {
        margin-left: 0;
        color: $color-bronze-text;

        &Selected {
          position: relative;
          background: $color-bronze--12;

          &::after {
            position: absolute;
            top: 105px;
            left: 48%;
            display: block;
            width: 16px;
            height: 16px;
            content: "";
            transform: rotate(45deg);
            background: $color-background-2;
          }
        }
      }

      &Level1 {
        position: relative;
        color: $color-silver-text;

        &Selected {
          background: $color-silver--12;

          &::after {
            position: absolute;
            top: 105px;
            left: 48%;
            display: block;
            width: 16px;
            height: 16px;
            content: "";
            transform: rotate(45deg);
            background: $color-background-2;
          }
        }
      }

      &Level2 {
        position: relative;
        margin-right: 0;
        color: $color-gold-text;

        &Selected {
          background: $color-gold--12;

          &::after {
            position: absolute;
            top: 105px;
            right: 48%;
            display: block;
            width: 16px;
            height: 16px;
            content: "";
            transform: rotate(45deg);
            background: $color-background-2;
          }
        }
      }

      &Bird {
        display: block;
        width: 32px;
        height: 32px;
        margin: auto;
        stroke-width: 0 !important;

        &Selected {
          animation: jump 400ms ease-in-out;
        }

        &LeaveSelected {
          animation: jump_leave 400ms ease-in-out;
        }
      }
    }
  }

  .chartContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  @keyframes jump {
    0% {
      transform: translateY(0);
    }

    30% {
      transform: translateY(6px);
    }

    75% {
      transform: translateY(-2px);
    }

    100% {
      transform: translateY(0);
    }
  };

  @keyframes jump_leave {
    0% {
      transform: translateY(0);
    }

    30% {
      transform: translateY(6px);
    }

    75% {
      transform: translateY(-2px);
    }

    100% {
      transform: translateY(0);
    }
  };

  @keyframes animate_further_row_multiple {
    0% {
      height: 195px;
    }

    75% {
      height: 198px;
    }

    100% {
      height: 195px;
    }
  };

  @keyframes animate_further_row_single {
    0% {
      height: 115px;
    }

    75% {
      height: 118px;
    }

    100% {
      height: 115px;
    }
  };
</style>
