import { defineAsyncComponent } from 'vue';
import type { Cct } from '~~/common/utils/Cct.ts';
import AwSfRecommendation from '~~/common/components/Common/AwSfRecommendation.vue';
const AwRecommendationCarousel = defineAsyncComponent(() => import('~~/common/components/AwRecommendationCarousel.vue'));

export const HT_STANDARD = 'standard';
export const HT_CAROUSEL = 'carousel';
export const HT_CATALOG = 'catalog';
export const HT_RECOMMENDATION_CAROUSEL_PRODUCT = 'recommendation_carousel_product';
export const HT_LAST_VIEWED_PRODUCTS = 'last_viewed_products';
export const HT_PRODUCT_REVIEW = 'product_reviews';

const CONTENT_PAGE_TEMPLATES = {
  BANNER: 'banner',
  SHORTLIST: 'shortlist',
  WHY_CHOOSE_AUCHAN: 'why_choose_auchan',
  CAROUSEL: HT_CAROUSEL,
  MAP: 'map',
  FAQ: 'faq',
  TILE_PROMOTION: 'tile_promotion',
  LOYALTY: 'loyalty',
  NEWSLETTER: 'newsletter',
  SOCIAL: 'social',
  STANDARD: HT_STANDARD,
  MOBILE_APPS: 'mobile_apps',
  CONTACT_US: 'contact_us',
  CAREER_CONTACT: 'career_contact',
  OK_AUCHAN_MOBILE_APP: 'okauchan_mobile_app',
  HOW_TO_BUY: 'how_to_buy',
  LAST_VIEWED_PRODUCTS: HT_LAST_VIEWED_PRODUCTS,
  RECOMMENDATION_CAROUSEL: 'recommendation_carousel',
  RECOMMENDATION_CAROUSEL_PRODUCT: HT_RECOMMENDATION_CAROUSEL_PRODUCT,
  PRODUCT_REVIEW: HT_PRODUCT_REVIEW,
  EMPTY: 'empty',
  WELCOME: 'welcome',
  CATALOG: HT_CATALOG,
  PREVIOUSLY_ORDERED_PRODUCTS: 'previously_ordered_products',
  PRIZE_GAME: 'prize_game',
  CAREER_JOBS: 'career_jobs',
  LOYALTY_PROGRAM_DESCRIPTION: 'loyalty_program_description',
};

export const CONTENT_PAGE_ANALYTICS_STANDARD = 'standard';

const config: { [key: string]: Cct } = {
  [CONTENT_PAGE_TEMPLATES.BANNER]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwBannerPromotion.vue')),
    analytics: 'banner_promocio',
  },
  [CONTENT_PAGE_TEMPLATES.SHORTLIST]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwShortlist.vue')),
    analytics: 'shortlist',
  },
  [CONTENT_PAGE_TEMPLATES.WHY_CHOOSE_AUCHAN]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwWhyChooseAuchan.vue')),
    analytics: 'miert_valaszd_az_auchant',
  },
  [CONTENT_PAGE_TEMPLATES.CAROUSEL]: {
    component: defineAsyncComponent(() => import('~~/common/components/Common/AwCarouselImage.vue')),
    analytics: 'carousel',
  },
  [CONTENT_PAGE_TEMPLATES.MAP]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwMapTab.vue')),
    analytics: 'terkep',
  },
  [CONTENT_PAGE_TEMPLATES.FAQ]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwFaq.vue')),
    analytics: 'faq',
  },
  [CONTENT_PAGE_TEMPLATES.TILE_PROMOTION]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwTilePromotion.vue')),
    analytics: 'csempe_promocio',
  },
  [CONTENT_PAGE_TEMPLATES.LOYALTY]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwReg.vue')),
    analytics: 'bizalom',
  },
  [CONTENT_PAGE_TEMPLATES.NEWSLETTER]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwNewsletter.vue')),
    analytics: 'hirlevel',
  },
  [CONTENT_PAGE_TEMPLATES.SOCIAL]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwSocial.vue')),
    analytics: 'social',
  },
  [CONTENT_PAGE_TEMPLATES.STANDARD]: {
    component: defineAsyncComponent(() => import('~~/common/components/Page/Content/AwStandard.vue')),
    analytics: CONTENT_PAGE_ANALYTICS_STANDARD,
  },
  [CONTENT_PAGE_TEMPLATES.MOBILE_APPS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwMobileApplicationBlock.vue')),
    analytics: 'mobil_applikacio',
  },
  [CONTENT_PAGE_TEMPLATES.CONTACT_US]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwCmsContactUs.vue')),
    analytics: 'lepj_kapcsolatba',
  },
  [CONTENT_PAGE_TEMPLATES.CAREER_CONTACT]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwCmsCareerContact.vue')),
    analytics: 'karrier_kapcsolat',
  },
  [CONTENT_PAGE_TEMPLATES.OK_AUCHAN_MOBILE_APP]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwOkAuchanMobileApp.vue')),
    analytics: 'okauchan_app',
  },
  [CONTENT_PAGE_TEMPLATES.HOW_TO_BUY]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwHowToBuy.vue')),
    analytics: 'hogyan_tudsz_rendelni',
  },
  [CONTENT_PAGE_TEMPLATES.LAST_VIEWED_PRODUCTS]: {
    component: AwRecommendationCarousel,
    analytics: 'utoljara_megtekintett_termek',
  },
  [CONTENT_PAGE_TEMPLATES.RECOMMENDATION_CAROUSEL]: {
    component: AwRecommendationCarousel,
    analytics: 'ezek_is_erdekelhetnek',
  },
  [CONTENT_PAGE_TEMPLATES.RECOMMENDATION_CAROUSEL_PRODUCT]: {
    component: AwSfRecommendation,
    analytics: 'ezek_is_erdekelhetnek_termekek',
  },
  [CONTENT_PAGE_TEMPLATES.PRODUCT_REVIEW]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwProductReviewFromStore.vue')),
    analytics: 'termek_ertekeles',
  },
  [CONTENT_PAGE_TEMPLATES.EMPTY]: {
    component: 'div',
    analytics: 'üres',
  },
  [CONTENT_PAGE_TEMPLATES.WELCOME]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwWelcome.vue')),
    analytics: 'udvozles',
  },
  [CONTENT_PAGE_TEMPLATES.CATALOG]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwCatalog.vue')),
    analytics: 'katalogus',
  },
  [CONTENT_PAGE_TEMPLATES.PREVIOUSLY_ORDERED_PRODUCTS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwPreviouslyOrderedProducts.vue')),
    analytics: 'korabbi_rendeleseid',
  },
  [CONTENT_PAGE_TEMPLATES.PRIZE_GAME]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwPrizeGame.vue')),
    analytics: 'nyeremenyjatek',
  },
  [CONTENT_PAGE_TEMPLATES.CAREER_JOBS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwCareerCardShortlist.vue')),
    analytics: 'karrier_allasok',
  },
  [CONTENT_PAGE_TEMPLATES.LOYALTY_PROGRAM_DESCRIPTION]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwLoyaltyProgramDescription.vue')),
    analytics: 'bizalom_program_leiras',
  },
};
export default config;
