<template>
  <div v-if="needRecommendedProduct" :class="$style.wrapper">
    <div ref="scriptContainer" />
    <aw-sf-product-carousel
      v-for="(recommendation, index) in awSfRecommendationStore.recommendations"
      :key="recommendation.name"
      :title="index === 0 ? $awt(`aw.sf_recommendation.${page}.title`) : recommendation.title"
      :placeholder-text="$awt(`aw.sf_recommendation.${page}.placeholder`)"
      :products="getProducts(recommendation)"
    />
  </div>
</template>

<script setup>
  import { computed, nextTick, provide } from 'vue';
  import { useRuntimeConfig, useNuxtApp } from 'nuxt/app';
  import { useAwSfRecommendationStore } from '~~/common/stores/awSfRecommendation.js';
  import AwSfProductCarousel from '~~/common/components/Common/AwSfProductCarousel.vue';
  import { useProductStore } from '~~/common/stores/product.js';

  const { $awt } = useNuxtApp();

  const runtimeConfig = useRuntimeConfig().public;

  const awSfRecommendationStore = useAwSfRecommendationStore();

  provide('isCartPage', false);

  const props = defineProps({
    page: {
      type: String,
      required: false,
      default: 'product',
    },
    forItems: {
      type: String,
      required: false,
      default: () => useProductStore().data?.selectedVariant?.sku?.toString(),
    },
  });

  const scriptContainer = ref(null);

  const needRecommendedProduct = computed(() => {
    return runtimeConfig.SfmcMid && runtimeConfig.isSfmcEnabled && runtimeConfig.isSfmcRecoEnabled;
  });

  const itemParams = computed(() => {
    return props.page === 'cart'
      ? `cart=${props.forItems}`
      : `item=${props.forItems}`;
  });

  const script = computed(() => {
    // eslint-disable-next-line
    return `<script src="https://${runtimeConfig.SfmcMid}.recs.igodigital.com/a/v2/${runtimeConfig.SfmcMid}/${props.page}/recommend.json?${itemParams.value}&callback=useNuxtApp().$awSFMCClient.getRecommendation" type="text/javascript"><\/script>`;
  });

  onMounted(() => {
    nextTick(() => {
      if (!needRecommendedProduct.value) {
        return;
      }

      try {
        if (scriptContainer.value) {
          const postscribe = () => import('postscribe');

          postscribe().then((postscribe) => {
            postscribe.default(null, null, 'clear');
            if (scriptContainer.value) {
              postscribe.default(scriptContainer.value, script.value);
            }
          });
        }
      } catch (error) {
        this.$logger.error(error);
      }
    });
  });

  onUnmounted(() => {
    awSfRecommendationStore.clearRecommendation();
  });

  function getProducts (recommendation) {
    return recommendation.products.map((product) => {
      return {
        ...product,
        ...(recommendation.items.find((item) => { return item.product_code === product.selectedVariant.sku; })),
      };
    });
  }

</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  margin-bottom: 32px;

  .carousel {
    margin-top: 24px;
  }
}

.tabCarouselPlaceHolder {
  margin-top: 2px;
}

</style>
