<template>
  <div :class="$style.wrapper">
    <client-only placeholder="loading...">
      <aw-tab-carousel
        ref="tabCarousel"
        :main-title="title"
        v-bind="sfProducts"
        tab-key="id"
        slide-key="id"
        :class="$style.carousel"
        :empty-placeholder="placeholderText"
        @render-sub-slides="onRender"
      >
        <template #slide(all)(all)="{ slide }">
          <div :class="['awProductCardPlaceholder', $style.tabCarouselCardPlaceholder]">
            <aw-product-card-version2
              v-if="productCardSize"
              :key="slide.id"
              heading-tag="h3"
              :product-data="slide"
              :card-size="productCardSize"
              :has-compare="false"
              :opened-details="false"
              is-recommended-product
            />
          </div>
        </template>
      </aw-tab-carousel>
    </client-only>
  </div>
</template>

<script setup>
  import { computed, nextTick } from 'vue';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { getProductCardSizeV2 } from '~~/common/utils/productCard.js';
  import AwProductCardVersion2 from '~~/common/components/Common/AwProductCardVersion2.vue';
  import AwTabCarousel from '~~/common/components/AwTabCarousel.vue';
  import { useNuxtApp } from 'nuxt/app';

  const { $awEvents } = useNuxtApp();

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      required: true,
    },
  });

  const tabCarousel = ref(null);
  const productCardSize = ref(null);

  const screenRange = computed(() => useUserInterfaceStore().mediaQueries);

  const sfProducts = computed(() => {
    return {
      tabs: [
        {
          id: 'default',
          tabTitle: '',
        },
      ],
      breakpoints: [
        {
          at: screenRange.value['desktop-small-min'],
          count: 4,
        },
        {
          at: screenRange.value['tablet-min'],
          count: 3,
        },
        {
          at: true,
          count: 'auto',
        },
      ],
      slides: {
        default: props.products,
      },
    };
  });

  onMounted(() => {
    $awEvents.addEvent('throttled-resize', setCardSize);
  });

  onUnmounted(() => {
    $awEvents.removeEvent('throttled-resize', setCardSize);
  });

  async function onRender () {
    if (!productCardSize.value) {
      await nextTick();
      await nextTick();
      if (!productCardSize.value) {
        setCardSize();
      }
    }
  }
  function setCardSize () {
    const placeholder = (tabCarousel.value?.$el?.querySelector?.('.awProductCardPlaceholder'));
    const clientWidth = placeholder?.clientWidth;
    const pcs = getProductCardSizeV2(clientWidth);

    if (pcs) {
      productCardSize.value = pcs;
    }
  }

</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  margin-bottom: 32px;

  .carousel {
    margin-top: 24px;
  }
}

.tabCarouselPlaceHolder {
  margin-top: 2px;
}

</style>
