<template>
  <div :class="[$style.cardSizeRoot, $style[`cardSizeRoot--${cardSize}`]]">
    <div
      :class="[
        `productCard-${productId}`,
        $style.productCard,
        {
          [$style['productCard--isInCart']]: isInCart,
          [$style['productCard--isBordered']]: bordered,
          [$style['productCard--isDetailsClosed']]: !isDetailsOpened,
          [$style[`productCard--${productPriceType.priceType && productPriceType.priceType.description}`]]: productPriceType.priceType,
          [$style[`productCard--isApplicable`]]: productPriceType.isApplicable,
        },
      ]"
      :style="dynamicProductCardStyles"
    >
      <!-- Warning: the elements in file are placed in order of appearance in the DOM. This is important for WCAG. The visual place of the elements can be changed with CSS. -->
      <div :class="$style.titleGridArea">
        <nuxt-link :class="$style.productTitle" :to="isRecommendedProduct && product.link ? product.link : productUrl(getVariant)">
          <component :is="headingTag" :title="productName" @click="navigateToProduct">
            {{ productName }}
          </component>
        </nuxt-link>
      </div>
      <span
        v-if="productPackageUnitSizeStr"
        :class="$style.packageUnitSizeGridArea"
        v-text="productPackageUnitSizeStr"
      />
      <div :class="$style.priceGridArea">
        <div :class="$style.priceGrid">
          <div v-if="discountPercentage && showDiscounted" :class="$style.discountPercentage">
            <span class="awSrOnlyAbs" v-text="$awt('aw.common.product_card.accessibility.discount')" />
            <span :class="{ awSrOnlyAbs: isBelowDiscountLimit }">
              -{{ discountPercentage }}%
            </span>
            <lv-icon
              v-if="isBelowDiscountLimit"
              name="pig-24"
              :size="24"
              style="stroke-width: 1.5"
            />
          </div>
          <!-- region primaryPrice -->
          <div :class="$style.currentPriceBox">
            <div :class="$style.currentPrice">
              <span class="awSrOnlyAbs" v-text="$awt('aw.common.product_card.accessibility.price')" />
              <span :class="$style.priceGross" v-text="productPrices.primary.main" />
              <div v-if="rollPrice" :class="$style.priceRoll">
                <lv-tooltip styling="primary-version2" :options="{placement: 'top-start'}">
                  <template #tooltip>
                    <div data-popover-item-wrapper>
                      <span
                        v-text="$awt(`aw.common.product_card.accessibility.${rollType === 'redemption' ? 'redemption_info' : 'roll_info'}`)"
                      />
                    </div>
                  </template>
                  <template #reference>
                    <span :class="$style.priceRollText">
                      +&nbsp;{{ rollPrice }}
                    </span>
                  </template>
                </lv-tooltip>
              </div>
            </div>
            <div v-if="productPrices.primary.units.length">
              <span class="awSrOnlyAbs" v-text="$awt('aw.common.product_card.accessibility.unit_price')" />
              <span v-html="productPrices.primary.units.join('<br>')" />
            </div>
          </div>
          <!-- endregion primaryPrice -->
          <div v-if="productPriceType.priceType" :class="$style.originalPriceBox">
            <!-- region strikeThoughPrice -->
            <div v-if="productPriceType.isApplicable" :class="$style.originalPriceValues">
              <div v-if="productPrices.secondary.main" :class="$style.originalPriceValueFull">
                <span class="awSrOnlyAbs" v-text="$awt('aw.common.product_card.accessibility.regular_price')" />
                <del :class="['delReset', $style.originalPrice]" v-html="productPrices.secondary.main" />
              </div>
              <div v-if="productPrices.secondary.units.length" :class="$style.originalPriceValueUnits">
                <span class="awSrOnlyAbs" v-text="$awt('aw.common.product_card.accessibility.regular_unit_price')" />
                <template v-for="(p, i) in productPrices.secondary.units">
                  <del
                    v-if="p"
                    :key="i"
                    :class="['delReset', $style.originalPriceValueUnit]"
                    v-html="p"
                  />
                </template>
              </div>
            </div>
            <!-- endregion strikeThoughPrice -->
            <!-- region notAppliedPrice -->
            <div
              v-if="!productPriceType.isApplicable && [PT_PRICE_STICKER, PT_PRICE_LOYALTY, PT_PRICE_COMBI, PT_PRICE_BUNDLE].includes(productPriceType.priceType)"
              :class="$style.promoGrid"
            >
              <button
                type="button"
                :title="offerModalData.title"
                :aria-label="offerModalData.title"
                :class="['buttonReset', $style.promoIcon]"
                @click="openOfferModal"
              >
                <lv-icon :name="notAppliedPromoIcon" :size="16" />
              </button>
              <div :class="$style.promoName" v-text="offerModalData.shortName" />
              <div :class="$style.promoPrice" v-html="notAppliedPromoPriceStr" />
            </div>
            <!-- endregion notAppliedPrice -->
          </div>
        </div>
      </div>

      <form
        :class="[$style.rowFull, $style.cartForm]"
        :style="{ visibility: getUnCachedProduct ? 'visible' : 'hidden' }"
      >
        <div v-if="portalTargetStock" :id="portalTargetStock" />
        <div
          v-show="isBasketControlShown"
          :class="[$style.cartBtnWrap, {[$style.cartBtnWrapIsStatusShowed]: showedStatusMessageFull }]"
        >
          <aw-add-to-basket-version2
            v-if="isOrderReady"
            :portal-target-stock="portalTargetStock"
            is-stock-change-allowed
            :is-status-visible="isBasketControlShown"
            is-cart-button-visible
            is-unit-selector-visible
            :is-basket-page="isBasketPage"
            :is-button-icon-visible="cardSize === 'tablet' && !isDetailsOpened"
            :is-button-text-visible="!(cardSize === 'tablet' && !isDetailsOpened)"
            :is-unit-selector-small="cardSize === 'mobile' && !isDetailsOpened"
            :is-quantity-selector-small="cardSize === 'mobile' && !isDetailsOpened"
            :is-quantity-selector-double-sided="cardSize === 'mobile' && !isDetailsOpened"
            quantity-selector-direction="vertical"
            :product-data="productData"
            :class="[$style.addToBasket, getAnaliticsClass]"
            inner-class-stock=""
            :inner-class-unit="$style.unitTarget"
            :inner-class-quantity="$style.quantityTarget"
            :inner-class-cart-btn="$style.cartBtnTarget"
            :inner-class-status="$style.statusTarget"
            :always-show-loyalty-price="alwaysShowLoyaltyPrice"
            @added-to-cart="setAddedToCBasketStatus"
            @quantity-change="setQuantity($event)"
          />
        </div>
      </form>

      <div v-if="hasCompare" :class="[$style.rowFull, $style.rowCentered]">
        <lv-button styling="link-primary-version2" @click="doCompare">
          <span :class="$style.buttonText" v-text="$awt('aw.common.product_card.compare')" />
        </lv-button>
      </div>
      <div v-if="!isShop && pageType !=='compare'" :class="[$style.rowFull, $style.rowCentered]">
        <lv-button styling="link-primary-version2" @click="navigateToSimilarProducts">
          <span :class="$style.buttonText" v-text="$awt('aw.common.product_card.similar_products')" />
        </lv-button>
      </div>
      <div :class="$style.overImageGridArea">
        <aw-favourite-version2
          v-if="isShop"
          :is-disabled="!getUnCachedProduct"
          :selected-value="selectedValue"
          :count="count"
          :variant-id="variantId"
          :product-id="productId"
          :shopping-list-data="getUnCachedProduct ? getUnCachedProduct.shoppingListsContain : []"
          :product-name="productName"
          :product-image="image"
          :product-data="product"
          :class="$style.imgTopRight"
        />
        <div :class="$style.imgBottomLeft">
          <aw-product-flag-items
            :flag-arr="displayedFlags"
            :product-data="productData"
            :max-length="isShop ? 2 : 3"
            :is-small="cardSize === 'mobile'"
            flag-bg="rgba(243, 240, 237, 0.85)"
            :class="[$style.productCardFlagItem, {[$style.productCardFlagItemVertical]: isShop, [$style.productCardFlagItemWrap]: !isShop}]"
            @activate-filter="$emit('activate-filter')"
          />
        </div>
        <div v-if="!isShop" :class="[$style.imgTopRight, $style.priceMarginIndicatorGroup]">
          <adc-stock-status :product-id="productId" />
          <adc-price-margin-indicator
            v-if="isLoggedInDealer"
            :margin-indicator-level="productData.marginIndicatorLevel"
          />
        </div>
        <div
          v-if="isDetailsOpened && featuredBrandImg"
          :class="$style.imgBottomRight"
          :style="featuredBrandImg.styles"
          :title="featuredBrandImg.text || null"
        >
          <span class="awSrOnlyAbs" v-text="featuredBrandImg.text" />
        </div>
      </div>
      <dl v-if="isProductCodeAreaShown" :class="$style.productCodeGridArea">
        <dt :class="[$style.productCodeTableItem, 'pbmoReset']" v-text="$awt('aw.common.product.sku')+':'" />
        <dd :class="[$style.productCodeTableItem, 'pbmoReset']" v-text="sku" />
        <template v-if="isLoggedInDealer">
          <dt :class="[$style.productCodeTableItem, 'pbmoReset']" v-text="$awt('aw.common.product.ean')+':'" />
          <dd :class="[$style.productCodeTableItem, 'pbmoReset']" v-text="eanCode" />
        </template>
      </dl>
      <div :class="$style.tableGridArea">
        <template v-if="isDetailsOpened">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="185"
            viewBox="0 0 2 185"
            fill="none"
            preserveAspectRatio="none"
            :class="$style.svgBorder"
          >
            <path
              d="M1 0.5V184.5"
              stroke="#C2C0BE"
              stroke-dasharray="2 6"
              vector-effect="non-scaling-stroke"
            />
          </svg>
          <dl v-if="highlightedParameters">
            <template v-for="p in highlightedParameters" :key="`key_${p.id}`">
              <dt :class="['pbmoReset', $style.tableElement]">
                {{ p.parameterName }}
                <span aria-hidden="true" v-text="'-'" />
              </dt>
              <dd :class="['pbmoReset', $style.tableElement, $style.tableElementValue]">
                {{ p.parameterValue }}<br aria-hidden="true">
              </dd>
            </template>
          </dl>
        </template>
      </div>
      <nuxt-link
        aria-hidden="true"
        tabindex="-1"
        :to="productUrl(getVariant, productData.categories)"
        :class="$style.imageGridArea"
      >
        <span :class="$style.productImage" @click="navigateToProduct">
          <lv-image v-bind="thumbImageOrVid" />
        </span>
      </nuxt-link>
      <template v-if="isInCart || bordered">
        <div :class="$style.imageGridAreaBottomRadiusHelper" />
        <div :class="$style.imageGridAreaBottomRadiusHelper2" />
      </template>

      <aw-product-old-product-modal
        :modal-type="modalType"
        :product-name="productName"
        @return-used-item="setReturnUsedItem"
      />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'pinia';
  import { useRoute, useRuntimeConfig } from 'nuxt/app';
  import { LvIcon, LvButton, LvImage, LvTooltip } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { M_PROD_OLD } from '~~/common/config/Modal.js';
  import { PUSH_SELECT_ITEM } from '~~/common/plugins/aw-analytics.js';
  import AnaliticsClasses from '~~/common/config/AnaliticsClasses';
  import productDataMixin from '~~/common/mixins/productDataMixin';
  import pageInstanceMixin from '~~/common/mixins/pageInstanceMixin';
  import urlMixin from '~~/common/mixins/urlMixin';
  import { uuid4, getLocaleNum } from '~~/common/utils';
  import { determinePriceType } from '~~/common/utils/productPriceType.js';
  import {
    PT_PRICE_DISCOUNT,
    PT_PRICE_STICKER,
    PT_PRICE_LOYALTY,
    PT_PRICE_BUNDLE,
    PT_PRICE_COMBI,
  } from '~~/common/config/priceTypes.js';
  import * as priceTypes from '~~/common/config/priceTypes.js';

  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useCategoryStore } from '~~/common/stores/category';
  import { useProductsStore } from '~~/common/stores/products';
  import { useUserStore } from '~~/common/stores/user';
  import { useStockInfoStore } from '~~/dealer/stores/stockInfo';

  const offerDetailsModalContent = {
    [PT_PRICE_STICKER]: 'loyalty_sticker_offer',
    [PT_PRICE_LOYALTY]: 'loyalty_card_offer',
    [PT_PRICE_COMBI]: 'combination_offer',
    [PT_PRICE_BUNDLE]: 'bundle_offer',
  };

  const validOpenedDetails = ['auto', true, false];

  export default {
    name: 'AwProductCardVersion2',
    components: {
      AwAddToBasketVersion2: defineAsyncComponent(() => import('~~/common/components/Page/Common/BasketControl/AwAddToBasketVersion2.vue')),
      AwProductOldProductModal: defineAsyncComponent(() => import('~~/common/components/Page/Product/AwProductOldProductModal.vue')),
      AwFavouriteVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwFavouriteVersion2.vue')),
      AdcStockStatus: defineAsyncComponent(() => import('~~/dealer/components/AdcStockStatus.vue')),
      AdcPriceMarginIndicator: defineAsyncComponent(() => import('~~/dealer/components/AdcPriceMarginIndicator.vue')),
      AwProductFlagItems: defineAsyncComponent(() => import('~~/common/components/Page/Product/AwProductFlagItems')),
      LvIcon,
      LvButton,
      LvImage,
      LvTooltip,
    },
    mixins: [
      pageInstanceMixin,
      urlMixin,
      productDataMixin,
    ],
    props: {
      headingTag: {
        type: String,
        default: 'h3',
      },
      productData: {
        type: Object,
        required: true,
      },
      hasCompare: {
        type: Boolean,
        required: true,
      },
      isRecommendedProduct: {
        type: Boolean,
        default: false,
      },
      alwaysShowLoyaltyPrice: {
        type: Boolean,
        default: false,
      },
      cardSize: {
        type: String,
        required: true,
        validator: size => ['mobile', 'tablet', 'desktop'].includes(size),
      },
      openedDetails: {
        type: [String, Boolean],
        required: true,
        validator: v => validOpenedDetails.includes(v),
      },
      listIndex: {
        type: Number,
        default: null,
      },
      bordered: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['activate-filter'],
    data () {
      return {
        ...priceTypes,
        modalType: M_PROD_OLD + uuid4(),
        selectedValue: '',
        count: 1,
        videoKeyToUpdate: 0,
        portalTargetStock: '',
      };
    },
    computed: {
      ...mapState(useUserStore, {
        isLoggedInDealer (state) {
          return !this.isShop && state.isEcomUser;
        },
        isBasketControlShown (state) {
          return this.isShop || state.isEcomUser;
        },
      }),
      ...mapState(useCategoryStore, {
        categoryId: state => state.category?.id,
      }),
      ...mapState(useUrlResourceStore, {
        pageType: state => state.data?.resourceType,
      }),
      ...mapState(useOrderStore, {
        isOrderReady: state => !!(state.order && 'splits' in state.order),
        order: state => state.order,
        returnUsedItem: state => state.returnUsedItem,
        isInCart (state) {
          return state.getCurrentFlattenedOrderItems?.some(i => i.variant.productId === this.productId);
        },
        uuids (state) {
          return state.getCurrentFlattenedOrderItems.map((item) => {
            if (item.objectId === this.variantId.toString() && item.usedItem.canReturn && this.returnUsedItem[this.variantId] == null) {
              return item.uuid;
            }
            return undefined;
          }).filter(item => item);
        },
      }),
      isBasketPage () {
        const route = useRoute();
        return /.*?\/basket/gi.test(route.fullPath);
      },
      notAppliedPromoIcon () {
        return [PT_PRICE_STICKER, PT_PRICE_LOYALTY].includes(this.productPriceType.priceType) ? 'cheque-16' : 'pig-16';
      },
      dynamicProductCardStyles () {
        return {
          'grid-template-areas': `"${[...gridTemplateAreas(this)].join('" "')}"`,
        };
      },
      isProductCodeAreaShown () {
        return !this.isShop;
      },
      highlightedParameters () {
        return Array.isArray(this.productData?.highlightedParameters) ? this.productData.highlightedParameters : [];
      },
      isDetailsOpened () {
        return Boolean(this.openedDetails === true || (this.openedDetails === 'auto' && this.highlightedParameters.length));
      },
      productPackageUnitSizeStr () {
        let str = '';
        const packageSize = this.getVariant?.packageInfo?.packageSize;
        const packageUnit = this.getVariant?.packageInfo?.packageUnit?.toLowerCase?.();
        const qss = this.getVariant?.cartInfo?.quantityStepSize;
        const locale = this.$i18n.locale;
        const qssIsNotOne = (qss || qss === 0) && qss !== 1;
        if (qssIsNotOne) {
          str += `${getLocaleNum(locale, qss)}\u00A0\u00D7\u00A0`;
        }
        if (this.isShop || packageSize !== 1 || packageUnit !== 'db' || qssIsNotOne) {
          str += `${getLocaleNum(locale, packageSize)}\u00A0${packageUnit}`;
        }
        return str;
      },
      isBelowDiscountLimit () {
        return this.discountPercentage < 10;
      },
      featuredBrandImg () {
        const img = this.getVariant?.media?.priorityProductBrandImage;
        if (img) {
          return {
            styles: {
              'background-image': `url(${img})`,
            },
            text: (this.productData?.brandName
              ? this.$awt(
                'aw.common.product_card.featured_brand',
                {
                  brand_name: this.productData.brandName,
                },
              )
              : ''
            ),
          };
        } else {
          return null;
        }
      },
      productPriceType () {
        return determinePriceType({
          variant: this.getVariant,
          isShop: this.isShop,
          isLoyaltyPriceValid: this.getUnCachedProduct?.isLoyaltyPriceValid || this.alwaysShowLoyaltyPrice,
        });
      },
      offerModalData () {
        const content = offerDetailsModalContent[this.productPriceType.priceType];
        const shortName = {
          [PT_PRICE_STICKER]: 'aw.common.product_card.price_sticker.short_name',
          [PT_PRICE_LOYALTY]: 'aw.common.product_card.price_loyalty.short_name',
          [PT_PRICE_COMBI]: 'aw.common.product_card.offer_types.combi',
          [PT_PRICE_BUNDLE]: 'aw.common.product_card.offer_types.bundle',
          [PT_PRICE_DISCOUNT]: 'aw.common.product_card.offer_types.discount',
        }[this.productPriceType.priceType];
        return {
          content,
          title: this.$awt(`aw.static_content_page.${content}`),
          shortName: this.$awt(shortName),
        };
      },
      thumbImageOrVid () {
        const timg = this.thumbImage;
        if (timg.videoId) {
          return {
            key: `${this.variantId}-${this.videoThumbnailKey(timg.videoId)}-${this.videoKeyToUpdate}`,
            type: 'youtube',
            sources: this.getYoutubeThumbnailSources(timg.videoId),
          };
        } else {
          return {
            width: '152',
            height: '152',
            type: 'variant',
            sources: timg,
            sizing: 'contain',
            alt: this.productName,
          };
        }
      },
      getAnaliticsClass () {
        return {
          [AnaliticsClasses.addToCart.recommendedProduct]: this.isRecommendedProduct,
          [AnaliticsClasses.addToCart.boutique]: this.pageType === 'boutique',
          [AnaliticsClasses.addToCart.category]: this.pageType === 'category',
        };
      },
      showDiscounted () {
        const stickerPrice = this.getVariant?.stickerPrice;
        const loyaltyPrice = this.getVariant?.loyaltyPrice;
        const loyaltyPriceValid = this.getUnCachedProduct?.isLoyaltyPriceValid || this.alwaysShowLoyaltyPrice;

        if (this.discountPercentage && !stickerPrice && ((loyaltyPrice && loyaltyPriceValid) || !loyaltyPrice)) {
          return true;
        }

        return !!(loyaltyPrice && loyaltyPriceValid);
      },
    },
    created () {
      this.selectedValue = this.variantUnit;
      this.count = this.getVariant.cartInfo.quantityStepSize;
    },
    mounted () {
      const uuid = uuid4();
      if (!this.isShop) {
        this.portalTargetStock = 'stock-' + uuid;
        const stockInfoStore = useStockInfoStore();
        stockInfoStore.fetchStock({
          productIds: [this.productId],
          details: false,
        });
      }
      this.timeoutId = setTimeout(() => {
        this.timeoutId = null;
        this.videoKeyToUpdate++;
      }, 1000);
    },
    unmounted () {
      clearTimeout(this.timeoutId || null);
    },
    methods: {
      async navigateToSimilarProducts () {
        const productsStore = useProductsStore();
        const route = useRoute();
        const treeId = productsStore?.filterBy?.subcategory?.[0] || this.categoryId || this.productCategoryId;
        const newQuery = await productsStore.fetchSimilarProductFilters({
          productId: this.productId,
          categoryId: treeId,
        });
        if (newQuery) {
          let categoryUrl;
          if (this.categoryId) {
            categoryUrl = route.path;
          } else {
            const categoryStore = useCategoryStore();
            const category = categoryStore.getCategoryFromTreeById({ treeId: parseInt(treeId) });
            categoryUrl = this.getCategoryUrl(category);
          }
          const s = new URLSearchParams();
          s.set('qq', newQuery);
          const newUrl = categoryUrl + '?' + s.toString();
          // TODO: itt kéne valami elegánsabb, sajnos a router nem tölt újra, ha csak a queryparaméter változik
          window.location.href = newUrl;
        }
      },
      navigateToProduct () {
        const categoryStore = useCategoryStore();
        const productsStore = useProductsStore();
        const route = useRoute();
        this.$awAnalytics[PUSH_SELECT_ITEM]({
          productData: this.product,
          searchText: productsStore.searchText,
          categoryName: categoryStore.category?.name,
          index: this.listIndex + 1,
        });
        categoryStore.setCategoryIdBeforeVisitProductPage(isNaN(parseInt(this.categoryId)) ? false : parseInt(this.categoryId));
        productsStore.saveProductListHistoryInformation({
          productId: this.productId,
          route: route.fullPath,
        });
      },
      async setReturnUsedItem (returnUsed) {
        const modalStore = useModalStore();
        const orderStore = useOrderStore();
        if (returnUsed) {
          for (const uuid of this.uuids) {
            await orderStore.updateCartItemReturnUsed({
              uuid,
              returnUsed,
              fromBasket: false,
            });
          }
          orderStore.setReturnUsedItem({ [this.variantId]: true });
        } else {
          orderStore.setReturnUsedItem({ [this.variantId]: false });
        }
        modalStore.hideProdOld({ modalType: this.modalType });
      },
      setQuantity (quantity) {
        this.count = quantity?.count;
        this.selectedValue = quantity?.unit;
      },
      setAddedToCBasketStatus (success) {
        const config = useRuntimeConfig();
        const modalStore = useModalStore();
        const orderStore = useOrderStore();
        if (!config.public.isShop) {
          const stockInfoStore = useStockInfoStore();
          stockInfoStore.fetchStock({
            productIds: [this.productId],
            details: false,
          });
        }

        if (success && this.productData?.usedItem?.canReturn) {
          if (orderStore.getCurrentFlattenedOrderItems.some((item) => {
            if (item.objectId === this.variantId.toString() && item.usedItem.canReturn && this.returnUsedItem[this.variantId] == null) {
              if (this.isShop) {
                return true;
              } else {
                return orderStore.getOrderItemDiscriminator(this.order, item).deliveryMode !== 'store';
              }
            } else {
              return false;
            }
          })) {
            modalStore.showProdOld({ modalType: this.modalType });
          }
        }
      },
      openOfferModal () {
        const modalStore = useModalStore();
        modalStore.openStaticContentModal({
          content: this.offerModalData.content,
          title: this.offerModalData.title,
        });
      },
    },
  };

  function * gridTemplateAreas (self) {
    yield 'imgArea imgArea';
    yield 'titleArea parameterArea';
    if (self.isProductCodeAreaShown) {
      yield 'productCodeArea parameterArea';
    }
    if (self.productPackageUnitSizeStr) {
      yield 'packageUnitSizeArea parameterArea';
    }
    yield 'priceArea parameterArea';
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
@function createSelector($list, $str) {
  $s: "";
  @each $l in $list {
    $s: $s + ", " + $str + $l;
  }
  $s: str-slice($s, 3);
  @return $s;
}

@mixin cardSize($cardSizes...) {
  // example:
  // @include cardSize(mobile, desktop) {
  //   CSS code which applies to mobile and desktop but not tablet
  // }
  $selectorEnd: createSelector($cardSizes, ".cardSizeRoot--");
  @at-root :is(#{$selectorEnd})#{if(&, " &", "")} {
    @content;
  }
}

%lineClamp {
  line-height: var(--lineHeight);
  display: -webkit-box;
  overflow: hidden;
  min-height: calc(var(--lineNumber) * var(--lineHeight));
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--lineNumber);
  -webkit-box-orient: vertical;
}

.rowFull {
  grid-column: 1/-1;
}

.rowCentered {
  justify-self: center;
}

.cardSizeRoot {
  display: flex;

  > .productCard {
    flex: 1;
  }
}

.productCard {
  --awProductCardPadding: 16px;
  // stylelint-disable length-zero-no-unit
  --awProductCardBorder: 0px;
  --awProductCardCartBtnBg: #{$product-card-bg-secondary-color};
  font-family: $secondary-font;
  position: relative;
  display: grid;
  padding: 0 var(--awProductCardPadding) var(--awProductCardPadding);
  color: $color-olive-750;
  border: var(--awProductCardBorder) solid transparent;
  border-radius: $product-card-radius;
  background: $product-card-bg-primary-color;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(3, auto) 1fr;
  gap: 16px 32px;

  &--isDetailsClosed {
    $pc: ".productCard";
    grid-template-columns: 1fr 0;
    column-gap: 0;

    @include cardSize(mobile) {
      --awProductCardPadding: 12px;
      &#{$pc}--isInCart {
        --awProductCardPadding: 10px;
      }
      &#{$pc}--isApplicable {
        --awProductCardCurrentPricePadding: 4px 8px;
      }
      gap: 12px 16px;
    }
  }

  &--isInCart {
    --awProductCardPadding: 14px;
    --awProductCardBorder: 2px;
    --awProductCardCartBtnBg: #{$product-card-bg-in-cart-secondary-color};
    padding-top: var(--awProductCardBorder, 0);
    border-color: $product-card-bg-in-cart-border-color;
    background: $product-card-bg-in-cart-primary-color;

    .discountPercentage {
      top: -2px;
      border-radius: 22px 0 12px;
    }

    .imageGridArea {
      margin-top: calc(var(--awProductCardBorder) * -1 * 2);
      margin-bottom: calc(var(--awProductCardBorder) * -1 * 2);
      border: var(--awProductCardBorder) solid $product-card-bg-in-cart-border-color;
      border-bottom-color: transparent;

      &BottomRadiusHelper, &BottomRadiusHelper2 {
        align-self: end;
        margin: 0 calc(-1 * (var(--awProductCardPadding) + var(--awProductCardBorder))) 0 calc(-1 * (var(--awProductCardPadding) + var(--awProductCardBorder)));
        grid-area: imgArea;
      }

      &BottomRadiusHelper {
        height: calc(#{$product-card-radius});
        margin-bottom: calc(var(--awProductCardBorder) * -1 * 2);
        border-radius: 0 0 $product-card-radius $product-card-radius;
        background: white;
      }

      &BottomRadiusHelper2 {
        height: calc(#{$product-card-radius} + 2px);
        border: var(--awProductCardBorder) solid $product-card-bg-in-cart-border-color;
        border-top-color: transparent;
        border-bottom-color: transparent;
        background: transparent;
      }
    }
  }

  &--isBordered:not(&--isInCart) {
    --awProductCardPadding: 14px;
    --awProductCardBorder: 1px;
    padding-top: var(--awProductCardBorder, 0);
    border-color: $product-card-bg-in-white-card-border-color;

    .discountPercentage {
      top: -2px;
      border-radius: 22px 0 12px;
    }

    .imageGridArea {
      margin-top: calc(var(--awProductCardBorder) * -1 * 2);
      margin-bottom: calc(var(--awProductCardBorder) * -1 * 2);
      border: var(--awProductCardBorder) solid $product-card-bg-in-white-card-border-color;
      border-bottom-color: transparent;

      &BottomRadiusHelper, &BottomRadiusHelper2 {
        align-self: end;
        margin: 0 calc(-1 * (var(--awProductCardPadding) + var(--awProductCardBorder))) 0 calc(-1 * (var(--awProductCardPadding) + var(--awProductCardBorder)));
        grid-area: imgArea;
      }

      &BottomRadiusHelper {
        height: calc(#{$product-card-radius});
        margin-bottom: calc(var(--awProductCardBorder) * -1 * 2);
        border-radius: 0 0 $product-card-radius $product-card-radius;
        background: white;
      }

      &BottomRadiusHelper2 {
        height: calc(#{$product-card-radius} + 2px);
        border: var(--awProductCardBorder) solid $product-card-bg-in-white-card-border-color;
        border-top-color: transparent;
        border-bottom-color: transparent;
        background: transparent;
      }
    }
  }

  &--priceSticker,
  &--priceLoyalty {
    --awProductCardOfferTypeIconColor: #{$color-sage};
    --awProductCardOfferTypeIconBg: #{$color-gray-nurse};
  }

  &--priceCombi,
  &--priceBundle {
    --awProductCardOfferTypeIconColor: #{$color-strawberry-500};
    --awProductCardOfferTypeIconBg: #F7E6E4;
  }

  &--isApplicable {
    --awProductCardCurrentPricePadding: 8px 12px;
  }

  &--isApplicable:is(&--priceSticker, &--priceLoyalty) {
    --awProductCardCurrentPriceColor: #{$color-sage};
    --awProductCardCurrentPriceBg: #{$color-gray-nurse};
    --awProductCardPercentColor: #{$color-white};
    --awProductCardPercentBg: #{$color-sage};
  }

  &--isApplicable:is(&--priceDiscount) {
    --awProductCardCurrentPriceColor: #{$color-white};
    --awProductCardCurrentPriceBg: #{$color-strawberry-500};
    --awProductCardPercentColor: var(--awProductCardCurrentPriceColor);
    --awProductCardPercentBg: var(--awProductCardCurrentPriceBg);
  }
}

.priceGridArea,
.productCodeTableItem,
.packageUnitSizeGridArea {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  @include cardSize(mobile) {
    font-size: 10px;
  }
}

.productCodeGridArea {
  grid-area: productCodeArea;
  margin-top: -12px;
}

.imageGridArea,
.overImageGridArea {
  grid-area: imgArea;
  margin: calc(-1 * var(--awProductCardBorder)) calc(-1 * (var(--awProductCardPadding) + var(--awProductCardBorder))) 0 calc(-1 * (var(--awProductCardPadding) + var(--awProductCardBorder)));
}

.tableGridArea {
  position: relative;
  grid-area: parameterArea;
}

.priceGridArea {
  grid-area: priceArea;
  align-self: end;
}

.titleGridArea {
  grid-area: titleArea;
}

.productTitle {
  @extend %lineClamp;
  --lineHeight: 24px;
  --lineNumber: 2;
  font-size: 16px;
  font-weight: 900;
  display: -webkit-inline-box;
  word-break: break-word;
  hyphens: auto;

  &:focus-visible {
    text-decoration: underline;
  }
}

.packageUnitSizeGridArea {
  grid-area: packageUnitSizeArea;
  margin-top: -12px;
}

.imageGridArea {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background: $color-white;
}

.overImageGridArea {
  position: relative;
  display: grid;
  grid-template-areas:
    "imgFlagArea imgTopRightArea"
    "imgFlagArea imgBottomRightArea";
  padding: 16px;

  @include cardSize(mobile) {
    padding: 12px;
  }
}

.imgBottomRight,
.productCardFlagItem [data-aw-product-flag-item] {
  z-index: 1;
}

.imgTopRight {
  z-index: 2; // opened favourites tooltip needs to be higher
}

.productCardFlagItem {
  display: flex;
  align-items: flex-start;
  gap: 4px;

  &Vertical {
    flex-direction: column;
  }

  &Wrap {
    flex-wrap: wrap;
  }
}

.imgTopRight {
  grid-area: imgTopRightArea;
  justify-self: end;
  align-self: start;
}

.imgBottomRight {
  align-self: end;
  width: 47px;
  height: 47px;
  border: 7px solid $color-white;
  border-radius: 8px;
  background-color: $color-white;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 0 0 1px $color-border-disabled;
  grid-area: imgBottomRightArea;
  justify-self: end;
}

.imgBottomLeft {
  grid-area: imgFlagArea;
  justify-self: start;
  align-self: end;
}

.productImage {
  width: 151px;
  height: 151px;
  margin: 32px 0;

  picture {
    border-radius: 8px;
  }
}

.priceGrid {
  display: grid;
  grid-template-areas:
    "originalPriceArea"
    "currentPriceArea";
  min-height: 100px;

  @include cardSize(mobile) {
    min-height: 88px;
  }
}

.currentPriceBox {
  display: inline-block;
  align-self: end;
  margin-top: 8px;
  padding: var(--awProductCardCurrentPricePadding, 8px 0);
  color: var(--awProductCardCurrentPriceColor, currentColor);
  border-radius: 12px;
  background: var(--awProductCardCurrentPriceBg, transparent);
  grid-area: currentPriceArea;
  justify-self: start;
}

.originalPriceBox {
  grid-area: originalPriceArea;
}

.originalPriceValueUnits {
  display: flex;
  flex-direction: column;
}

.originalPriceValues {
  position: relative;
  display: inline-flex;
  gap: 4px;

  .originalPriceValueUnit {
    position: relative;
  }

  .originalPriceValueFull,
  :nth-child(n + 2 of .originalPriceValueUnit) {
    &::before {
      position: absolute;
      top: 0.5em;
      width: 100%;
      height: 1px;
      content: "";
      transform: translateY(100%);
      background: currentColor;
    }
  }
}

.currentPrice {
  display: flex;
  gap: 4px;
}

.priceGross {
  font-size: 22px;
  font-weight: 900;
  line-height: 28px;
}

.originalPrice {
  font-weight: 900;
}

.priceRoll {
  align-self: flex-end;
  padding-bottom: 3px;
  @include desktop-medium(min) {
    padding-bottom: 4px;
  }
}

.priceRollText {
  font-weight: 700;
  text-decoration-line: underline;
  cursor: help;
}

.tableElement {
  font-size: 14px;
  line-height: 20px;
  display: inline;
}

.tableElement:not(.tableElementValue) {
  font-weight: 500;
}

.tableElementValue {
  font-weight: 900;
}

.buttonText {
  font-size: 14px;
  line-height: 20px;
}

.svgBorder {
  position: absolute;
  left: -16px;
  width: 2px;
  height: 100%;
}

.discountPercentage {
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  padding: 4px;
  color: var(--awProductCardPercentColor, $color-black);
  border-radius: 24px 0 12px;
  background: var(--awProductCardPercentBg, $color-white);
}

.promoGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 8px;
}

.promoName {
  @extend %lineClamp;
  --lineHeight: 16px;
  --lineNumber: 1;
  font-weight: 700;
}

.promoIcon {
  line-height: 0;
  align-self: center;
  padding: 6px;
  cursor: help;
  color: var(--awProductCardOfferTypeIconColor, $color-black);
  border-radius: 8px;
  background: var(--awProductCardOfferTypeIconBg, $color-white);
  grid-row: 1/-1;
  justify-self: center;
}

.cartForm {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include cardSize(mobile) {
    justify-content: end;
    min-height: 128px;
  }
}

.cartBtnWrap {
  display: flex;
  min-height: 68px;
  padding: 8px;
  gap: 8px 4px;
  border-radius: 16px;
  background: var(--awProductCardCartBtnBg);
}

.cartBtnWrapIsStatusShowed {
  border: 1px solid $color-border-disabled;
  background: transparent;
}

.unitTarget,
.quantityTarget,
.cartBtnTarget,
.statusTarget {
  &:empty {
    display: none;
  }
}

.statusTarget {
  @include productCardStatusTarget();
}

%wideCardBtn {
  .quantityTarget {
    min-width: 80px;
  }

  .cartBtnTarget {
    flex-grow: 1;
  }
}

.productCard {
  &:not(.productCard--isDetailsClosed) {
    @extend %wideCardBtn;
  }

  @include cardSize(desktop) {
    @extend %wideCardBtn;
  }

  &--isDetailsClosed {
    @include cardSize(mobile, tablet) {
      .quantityTarget {
        flex-grow: 1;
      }
    }
    @include cardSize(mobile) {
      .cartBtnWrap {
        flex-wrap: wrap;
      }

      .cartBtnTarget {
        flex-basis: 100%;
      }

      .priceGross {
        font-size: 18px;
        line-height: 24px;
      }

      .currentPriceBox {
        padding: var(--awProductCardCurrentPricePadding, 4px 0);
      }
    }
  }
}

.addToBasket {
  display: contents;
}

.productCodeTableItem {
  &:nth-child(2n+1) {
    float: left;
    margin-right: 1ch;
  }
}

.priceMarginIndicatorGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
